import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  useTheme,
  AppBar,
  Button,
  Switch,
  useMediaQuery,
  IconButton,
  List,
  ListItem,
  Drawer,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../state/index";
import FlexBetween from "../../components/FlexBetween";
import logo from "../../static/logo.png";
import {
  CloseOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  MenuOutlined,
} from "@mui/icons-material";

const navLinks = [
  {
    title: "Our Courses",
    link: "/courses",
  },
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Gallery",
    link: "/gallery",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
];

const Navbar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = useSelector((state) => state.mode);
  const isNonMediumScreens = useMediaQuery("(min-width: 1062px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [bgColor, setBgColor] = useState("#00000000");

  window.addEventListener("scroll", () => {
    if (window.scrollY > 66) {
      setBgColor(theme.palette.background.main);
    } else {
      setBgColor("#00000000");
    }
  });

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <AppBar
        position="fixed"
        top="0"
        left="0"
        right="0"
        sx={{
          width: "100%",
          backgroundColor: bgColor,
          boxShadow: "none",
          // borderBottom: `1px solid ${theme.palette.text.main}`,
          display: "flex",
          flexDirection: "row",
          zIndex: "20",
          color: theme.palette.text.main,
          justifyContent: "space-between",
          transition: "200ms ease",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexBasis: "calc(100%/3)",
            justifyContent: "flex-start",
            alignItems: "center",
            pl: isNonMediumScreens ? "40px" : "20px",
          }}
        >
          <Link to="/">
            <img src={logo} alt="BIIT Logo" width="64" height="64" />
          </Link>
        </Box>
        <FlexBetween
          sx={{
            gap: "1.25rem",
            flexBasis: "calc(100%/3)",
            // borderLeft: `1px solid ${theme.palette.text.main}`,
            justifyContent: "center",
            fontWeight: "bold",
            display: isNonMediumScreens ? "flex" : "none",
          }}
        >
          {navLinks.map((link) => (
            <Link to={link.link}>
              <Typography
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
              >
                {link.title}
              </Typography>
            </Link>
          ))}
        </FlexBetween>
        <FlexBetween
          sx={{
            gap: "1rem",
            flexBasis: "calc(100%/3)",
            // borderLeft: `1px solid ${theme.palette.text.main}`,
            justifyContent: "flex-end",
            fontWeight: "bold",
            pr: isNonMediumScreens ? "40px" : "20px",
            display: isNonMediumScreens ? "flex" : "none",
          }}
        >
          <FlexBetween
            sx={{
              pr: "15px",
              borderRight: `2px solid ${theme.palette.text.main}`,
            }}
          >
            <LightModeOutlined fontSize="small" />
            <Switch
              onClick={() => dispatch(setMode())}
              checked={mode === "dark"}
            />
            <DarkModeOutlined fontSize="small" />
          </FlexBetween>
          <Button variant="contained" sx={{ pl: "20px", pr: "20px" }}>
            <Link to="/contact-us">Enroll Now</Link>
          </Button>
        </FlexBetween>
        <Box
          display={isNonMediumScreens ? "none" : "flex"}
          justifyContent="center"
          alignItems="center"
          pr={isNonMediumScreens ? "40px" : "20px"}
        >
          <IconButton onClick={() => dispatch(setMode())}>
            <LightModeOutlined
              sx={{ display: mode === "light" ? "block" : "none" }}
            />
            <DarkModeOutlined
              sx={{ display: mode === "dark" ? "block" : "none" }}
            />
          </IconButton>
          <IconButton onClick={toggleDrawer}>
            <MenuOutlined />
          </IconButton>
        </Box>
      </AppBar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: 300,
            height: "100%",
            backgroundColor: theme.palette.background.secondary,
            padding: "10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <img
                src={logo}
                alt="BIIT Logo"
                style={{
                  filter:
                    mode === "light"
                      ? "brightness(0) invert(0)"
                      : "brightness(0) invert(1)",
                }}
                width="64"
                height="64"
              />
            </Box>
            <CloseOutlined
              onClick={toggleDrawer}
              sx={{
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            />
          </Box>
          <Box
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <List sx={{ width: "100%" }}>
              {navLinks.map((link) => (
                <ListItem
                  button
                  key={link.title}
                  component={Link}
                  to={link.link}
                  onClick={toggleDrawer}
                  sx={{ width: "100%" }} // Set width to 100% for the ListItem
                >
                  <ListItemText primary={link.title} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
