import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import emailjs from "emailjs-com";
import { MuiTelInput } from "mui-tel-input";
import contact from "../../static/contact_us.jpg";
import Footer from "../Footer/Footer";

const ContactUs = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1062px)");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMediumScreens ? 500 : "90%",
    bgcolor: theme.palette.background.main,
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    borderRadius: "10px",
  };
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    message: yup.string().required("Message is required"),
    phNo: yup.string().required("Phone Number is required"),
    course: yup.string(),
  });

  const initialValues = {
    name: "",
    email: "",
    message: "",
    phNo: "",
    course: "",
  };

  const onSubmit = (values, { resetForm }) => {
    const message = `Phone Number: ${values.phNo} \n Course: ${values.course} \n Message: ${values.message} `;
    // Prepare data for sending using EmailJS
    const emailData = {
      from_name: values.name,
      from_email: values.email,
      message: message,
    };

    // Replace with your EmailJS service ID and template ID
    const serviceId = "service_t1ot11g";
    const templateId = "template_2u0f9pd";
    const publicKey = "SULEIP4uKCzUJCeLW";

    // Send email using EmailJS
    emailjs
      .send(serviceId, templateId, emailData, publicKey)
      .then((response) => {
        console.log("Email sent successfully:", response);
        handleOpen();
        resetForm(); // Reset all form fields
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        // Handle error (e.g., show an error message)
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    document.title = "BIIT Classes | Contact Us";
  }, []);
  return (
    <>
      <Box
        display="flex"
        sx={{
          minHeight: "120vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
          alignItems: "center",
          pl: isNonMediumScreens ? "40px" : "0px",
        }}
      >
        <Box
          flex={1}
          sx={{
            backgroundImage: `url(${contact})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: isNonMediumScreens ? "block" : "none",
            borderRadius: "40px",
            height: "100vh",
          }}
        ></Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt="80px"
          px={isNonMediumScreens ? "40px" : "20px"}
          flex={2}
        >
          <Typography variant="display" component="h1">
            Contact Us
          </Typography>
          <Typography textAlign="center">
            Reach out and let's start a conversation. We're here to listen,
            assist, and make your inquiries count!
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <MuiTelInput
              fullWidth
              id="phNo"
              name="phNo"
              label="PhNo"
              variant="outlined"
              margin="normal"
              multiline
              value={formik.values.phNo}
              onChange={(phone) => formik.setFieldValue("phNo", phone)}
              error={formik.touched.phNo && Boolean(formik.errors.phNo)}
              helperText={formik.touched.phNo && formik.errors.phNo}
              defaultCountry="IN"
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="course-label">Select a Course</InputLabel>
              <Select
                labelId="course-label"
                id="course"
                name="course"
                value={formik.values.course}
                onChange={formik.handleChange}
                label="Select a Course"
              >
                <MenuItem value="None">None</MenuItem>{" "}
                <MenuItem value="JavaDevelopmentExpert">
                  Java Development Expert
                </MenuItem>
                <MenuItem value="FrontEndWebDev">
                  Front End Web Development
                </MenuItem>
                <MenuItem value="MERNStack">MERN Stack</MenuItem>
                <MenuItem value="Wordpress">Wordpress</MenuItem>
                <MenuItem value="PythonForDataScience">
                  Python For Data Science
                </MenuItem>
                <MenuItem value="PythonProgramming">
                  Python Programming
                </MenuItem>
                <MenuItem value="AndroidDev">Android Development</MenuItem>
                <MenuItem value="BCA">BCA</MenuItem>
                <MenuItem value="MCA">MCA</MenuItem>
                {/* Add more MenuItems as needed */}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              style={{ marginTop: "16px" }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 10 }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h2">
            Email Sent Successfully
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your message has been sent. Thank you for contacting us!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We will get back to you soon!
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default ContactUs;
