// Light mode colors
const primaryColor = "#012b57";
const secondaryColor = "#FFD700";
const tertiaryColor = "#F2F3F3";
const complementaryColor = "#800020";
const textColor = "#101010";

// Dark mode colors
const darkPrimaryColor = "#004887";
const darkSecondaryColor = "#FFC107";
const darkTertiaryColor = "#121212";
const darkComplementaryColor = "#6A1B9A";
const darkTextColor = "#F2F3F3E6";

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            //Dark Mode Colors
            primary: {
              main: darkPrimaryColor,
              light: primaryColor,
            },
            secondary: {
              main: darkSecondaryColor,
              light: secondaryColor,
            },
            background: {
              main: darkTertiaryColor,
              alt: tertiaryColor,
            },
            complementary: {
              main: darkComplementaryColor,
              light: complementaryColor,
            },
            text: {
              main: darkTextColor,
            },
          }
        : {
            // Light Mode Colors
            primary: {
              main: primaryColor,
              light: darkPrimaryColor,
            },
            secondary: {
              main: secondaryColor,
              light: darkSecondaryColor,
            },
            background: {
              main: tertiaryColor,
              alt: darkTertiaryColor,
            },
            complementary: {
              main: complementaryColor,
              light: darkComplementaryColor,
            },
            text: {
              main: textColor,
            },
          }),
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 16,
      display: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 56,
        fontWeight: "normal",
      },
      h1: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 36,
      },
      h3: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 32,
      },
      h4: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 28,
      },
      h5: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 24,
      },
      h6: {
        fontFamily: ["Bebas Neue", "sans-serif"].join(","),
        fontSize: 20,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "unset",
          },
        },
      },
    },
  };
};
