import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
} from "@mui/material";

const CourseCard = ({ title, description, buttonText, imageSrc }) => {
  return (
    <Card
      sx={{
        minWidth: 300,
        maxWidth: 300,
        m: 2,
        background: "#ffffff11",
        backdropFilter: "blur(20px)",
        minHeight: "400px",
      }}
    >
      <CardMedia
        component="img"
        width="100%"
        height="100%"
        image={imageSrc}
        alt={title}
      />
      <CardContent
        sx={{
          textAlign: "center",
          boxShadow: 2,
          height: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
        <Button variant="solid" color="primary" sx={{ mt: 2 }}>
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
