import React, { useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./scenes/HomePage/Home";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Layout from "./scenes/Layout/Layout";
import AboutUs from "./scenes/AboutUsPage/AboutUs";
import Gallery from "./scenes/GalleryPage/Gallery";
import ContactUs from "./scenes/ContactUsPage/ContactUs";
import Courses from "./scenes/CoursesPage/Courses";

const App = () => {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="bg-gradient">
            <div className="circle-01"></div>
            <div className="circle-02"></div>
            <div className="circle-03"></div>
          </div>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact-us" element={<ContactUs />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default App;
