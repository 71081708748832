import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import logo from "../../static/logo.png";
import {
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  LocalPhoneOutlined,
  LocationOnOutlined,
  Twitter,
  Contacts,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const socialLinks = [
  {
    icon: <FacebookOutlined />,
    name: "BIIT Professional",
    link: "https://www.facebook.com/Professionalstraining/",
  },
  {
    icon: <FacebookOutlined />,
    name: "BIIT IGNOU",
    link: "https://www.facebook.com/BIITClasses/",
  },
  {
    icon: <Instagram />,
    name: "BIIT Professional",
    link: "https://www.instagram.com/biit_classes_/",
  },
  {
    icon: <Instagram />,
    name: "BIIT IGNOU",
    link: "https://www.instagram.com/biitclasses_bca_mca_btech/",
  },
  {
    icon: <Twitter />,
    name: "Twitter",
    link: "https://twitter.com/biit_classes",
  },
  {
    icon: <Contacts />,
    name: "Just Dial",
    link: "https://www.justdial.com/Delhi/Biit-Classes-Near-Hanuman-Mandir-Indira-Park-Uttam-Nagar/011PXX11-XX11-170828103519-A4L5_BZDET",
  },
];

const informationLinks = [
  {
    icon: <LocationOnOutlined />,
    content:
      "RZ 11/12 Extn.Opp Hanuman Mandir, Pali factory road, Indra Park, Uttam Nagar, Delhi-110059",
    link: "/",
  },
  {
    icon: <LocalPhoneOutlined />,
    content: "+91-7290803744, 8700652427",
    link: "/",
  },
  {
    icon: <EmailOutlined />,
    content: "biitclasses@gmail.com",
    link: "/",
  },
];

const Footer = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.mode);
  const isMediumScreens = useMediaQuery("(min-width: 990px)");
  return (
    <Box
      px={isMediumScreens ? "40px" : "20px"}
      sx={{
        width: "100%",
        minHeight: "60vh",
        backgroundColor: theme.palette.background.alt,
        zIndex: 10,
        position: "relative",
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
        minHeight="60vh"
        pt="70px"
        pb="30px"
        sx={{
          "& > div": {
            gridColumn: isMediumScreens ? undefined : "span 12",
            alignItems: isMediumScreens ? undefined : "center",
            textAlign: isMediumScreens ? "left" : "center",
          },
        }}
      >
        <Box>
          <img
            src={logo}
            alt="BIIT Logo"
            style={{
              filter:
                mode === "light"
                  ? "brightness(0) invert(1)"
                  : "brightness(0) invert(0)",
            }}
            width="128"
            height="128"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          pr="5px"
          sx={{ color: theme.palette.background.main }}
        >
          <Typography variant="h3" mb="1rem">
            Information
          </Typography>
          {informationLinks.map((link, i) => (
            <Box display="flex" width="90%" gap="1rem" key={i}>
              {link.icon}
              <Typography variant="body2">{link.content}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          pr="5px"
          sx={{ color: theme.palette.background.main }}
        >
          <Typography variant="h3" mb="1rem">
            Social Links
          </Typography>
          {socialLinks.map((link, i) => (
            <a href={link.link} key={i} target="_blank" rel="noreferrer">
              <Box display="flex" width="90%" gap="1rem">
                {link.icon}
                <Typography variant="body2">{link.name}</Typography>
              </Box>
            </a>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          pr="5px"
          sx={{ color: theme.palette.background.main }}
        >
          <Typography variant="h3" mb="1rem">
            IGNOU
          </Typography>
          <a
            href="http://www.ignou.ac.in/ignou/studentzone/results/4"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="body2">IGNOU INFO </Typography>
          </a>
          <a
            href="http://www.ignou.ac.in/ignou/studentzone/results/2"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="body2">IGNOU Term-End </Typography>
          </a>
          <a
            href="http://www.ignou.ac.in/ignou/studentzone/results/5"
            target="_blank"
            rel="noreferrer"
          >
            <Typography variant="body2">IGNOU Revaluation </Typography>
          </a>
        </Box>

        <Box>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.25804244096!2d77.06502247550041!3d28.622027375670545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04dacc326a23%3A0xa1d6f84d3985bdd3!2sBiit%20Classes%20BCA%2FMCA!5e0!3m2!1sen!2sin!4v1692259299333!5m2!1sen!2sin"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Biit Classes"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
