import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Loader from "../Loader/Loader";

const Layout = () => {
  const { palette } = useTheme();
  return (
    <Box
      width="100%"
      sx={{ minHeight: "100vh", backgroundColor: palette.background.main }}
    >
      <Loader />
      <Navbar />

      <Outlet />
    </Box>
  );
};

export default Layout;
