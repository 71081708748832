import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import javaDev from "../../static/javaDev.pdf";
import wordpress from "../../static/wordpress.pdf";
import mernStack from "../../static/mernStack.pdf";
import frontEnd from "../../static/frontEnd.pdf";
import androidDev from "../../static/androidCourse.pdf";
import mernStackImg from "../../static/mernStack.jpg";
import wordpressImg from "../../static/wordpress.jpg";
import dataScienceImg from "../../static/dataScience.jpg";
import frontEndImg from "../../static/webdev.jpg";
import javaDevImg from "../../static/javaDev.jpg";
import python from "../../static/python.jpg";
import androidDevImg from "../../static/androidDev.jpg";
import threejsDevImg from "../../static/threejs-dev.jpg";
import modellingImg from "../../static/3dmodelling.jpg";
import Footer from "../Footer/Footer";

const coursesList = [
  {
    id: 1,
    title: "Java Development Expert",
    description: "Become an expert in Java programming with hands-on projects.",
    imageUrl: javaDevImg,
    pdf: javaDev,
  },
  {
    id: 2,
    title: "Front End Web Development",
    description:
      "Master front end web development technologies like HTML, CSS, and JavaScript.",
    imageUrl: frontEndImg,
    pdf: frontEnd,
  },
  {
    id: 3,
    title: "MERN Stack Web Development",
    description:
      "Master full stack web development technologies like MongoDB, Express.JS, React JS, and Node.JS",
    imageUrl: mernStackImg,
    pdf: mernStack,
  },
  {
    id: 4,
    title: "Three.js Web Development",
    description: "Master creating 3d websites which will wow any user",
    imageUrl: threejsDevImg,
    pdf: null,
    redirectTo: "https://threejs-dev.netlify.app/",
  },
  {
    id: 5,
    title: "3D Modelling and Animation",
    description:
      "Ready to turn your ideas into stunning visual creations? Dive into our exciting new course on 3D Modeling and Animation with Blender, Unreal Engine, and DaVinci Resolve!",
    imageUrl: modellingImg,
    pdf: null,
    redirectTo: "https://3dmodelling.netlify.app/",
  },
  {
    id: 6,
    title: "Wordpress",
    description: "Explore Wordpress and its features.",
    imageUrl: wordpressImg,
    pdf: wordpress,
  },
  {
    id: 7,
    title: "Python for Data Science",
    description: "Learn data analysis and machine learning using Python.",
    imageUrl: dataScienceImg,
    pdf: null,
  },
  {
    id: 8,
    title: "Python Programming Language",
    description: "Learn Python programming language fundamentals.",
    imageUrl: python,
    pdf: null,
  },
  {
    id: 9,
    title: "Android Development",
    description: "Learn Android app development.",
    imageUrl: androidDevImg,
    pdf: androidDev,
  },
  {
    id: 10,
    title: "BCA",
    description: "Explore Bachelor of Computer Applications (BCA) course.",
    imageUrl: "https://via.placeholder.com/300x200/F39C12/FFFFFF?text=BCA",
    pdf: null,
  },
  {
    id: 11,
    title: "MCA",
    description: "Explore Master of Computer Applications (MCA) course.",
    imageUrl: "https://via.placeholder.com/300x200/1ABC9C/FFFFFF?text=MCA",
    pdf: null,
  },
];

function Courses() {
  const isNonMediumScreens = useMediaQuery("(min-width: 990px)");
  useEffect(() => {
    document.title = "BIIT Classes | Courses";
  }, []);

  return (
    <>
      <Box
        width="100%"
        px={isNonMediumScreens ? "40px" : "20px"}
        pt="120px"
        pb="120px"
        display="flex"
        flexDirection="column"
        sx={{ zIndex: 10, position: "relative" }}
      >
        <Typography
          variant="display"
          component="h1"
          textAlign="center"
          sx={{ mb: "50px" }}
        >
          Our Courses
        </Typography>
        <Grid container spacing={3}>
          {coursesList.map((course) => (
            <Grid item key={course.id} xs={12} sm={6} md={3}>
              <a
                href={course.pdf !== null ? course.pdf : course.redirectTo}
                target="_blank"
                rel="noreferrer"
                download={course.pdf !== null ? course.title : false}
              >
                <Card sx={{ minHeight: "450px" }}>
                  <CardMedia
                    image={course.imageUrl}
                    title={course.title}
                    sx={{ height: "200px" }}
                  />
                  <CardHeader title={course.title} />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      {course.description}
                    </Typography>
                  </CardContent>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

export default Courses;
