import React, { useState } from "react";
import { Card, CardContent, Typography, Rating } from "@mui/material";

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + "...";
  }
  return text;
};

const TestimonialCard = ({ name, rating, content, wordLimit }) => {
  const [expanded, setExpanded] = useState(false);
  const truncatedContent = truncateText(content, wordLimit);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        minWidth: 350,
        maxWidth: 350,
        minHeight: 300,
        background: "#ffffff11",
        backdropFilter: "blur(20px)",
      }}
    >
      <CardContent>
        <Typography variant="h6">{name}</Typography>
        <Rating value={rating} readOnly />
        <Typography variant="body2" color="textSecondary">
          {expanded ? content : truncatedContent}
          {content.length > wordLimit && (
            <span
              onClick={handleExpandClick}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              {expanded ? " Read less" : " Read more"}
            </span>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
