import React, { useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./Home.css";
import hero from "../../static/our-mission.jpg";
import hero2 from "../../static/hero_2.jpg";
import hero3 from "../../static/hero.jpg";
import FlexBetween from "../../components/FlexBetween";
import CourseCard from "../../components/CourseCard";
// import webDev from "../../static/webDev.svg";
import about from "../../static/about.jpg";
import mernStack from "../../static/mernStack.jpg";
import wordpress from "../../static/wordpress.jpg";
import dataScience from "../../static/dataScience.jpg";
import meetTheTeacherRamjeet from "../../static/meet-the-teacher-ramjeet.jpg";
import meetTheTeacherRajesh from "../../static/meet-the-teacher-rajesh.jpg";
import meetTheTeacherJasmeher from "../../static/meet-the-teacher-jasmeher.jpg";
import meetTheTeacherSwati from "../../static/meet-the-teacher-swati.jpg";
import meetTheTeacherShashank from "../../static/meet-the-teacher-shashank.jpg";
import meetTheTeacherRuchika from "../../static/meet-the-teacher-ruchika.jpg";
import meetTheTeacherAkaash from "../../static/meet-the-teacher-akaash.jpg";
import meetTheTeacherChetna from "../../static/meet-the-teacher-chetna.jpg";
import placeholder from "../../static/placeholder.png";
import whyUsVideo from "../../static/whyUsVideo.MP4";
import TeacherProfileCard from "../../components/TeacherProfileCard";
import TestimonialCard from "../../components/TestimonialCard";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const testimonials = [
  {
    name: "Yash Garg",
    rating: 5,
    content:
      "I would like to share my great experience with biit classes...So friendly environment with best experienced faculties, who are always ready to clear our doubts any time... earlier I had extream fear of coding but trust me if any of you wanna to learn coding just go ahead with biit classes...I have learn c,c++,java and I'm so confident with my coding skills",
  },
  {
    name: "Abhinav Goel",
    rating: 5,
    content:
      "Quite good teaching methodologies and frank teachers so learning is based on more fun ideologies rather than stricter 19 century ones. Plus accomodating times and good lab experience with genuine help. I pursued C, c++ and basic data structures in crash course of 2.5 months before heading for university and it has helped.",
  },
  {
    name: "Nikhil Kumar",
    rating: 5,
    content:
      "Currently I'm pursuing in B. C. A through distance mode (I.G.N.O.U) and taking my regular sessions in BIIT classes cause it's a practical oriented course, We like the great ambience and positive environment as all mentors are very supportive and specially Mr. Raamjeet is very generous and polite and we're really thankful to get his mentorship😇😇😇",
  },
  {
    name: "Prabhneet Singh",
    rating: 5,
    content:
      "I'm glad that I came across this institute. The faculty is really cooperative and they make sure that proper attention to each individual student is given and that you're understanding every bit of what they're trying to teach. They even provide extra classes in case you've missed some. I've always found Ramjeet sir (owner) as a great teacher since he makes extra efforts to make sure that my learning experience went smoothly. I'll definitely recommend it to someone looking out for professional courses or someone who is facing difficulties in clearing their university exams.",
  },
  {
    name: "Aryan Giri",
    rating: 5,
    content:
      "Honestly it's a very good institute for your practical knowledge if you wanna learn anything for real. So from my point of view it was the best institute....even fees structure is also affordable so.....rest is up to you.....🖤💫",
  },
  {
    name: "Aashika Rawat",
    rating: 5,
    content:
      "Teachers and staff in this institute are excellent even I have missed some of the classes but they regularly provide backups for that. They made us understand the concepts very deeply by giving numerous examples which helps us in understanding the concept very clearly. According to me their teaching method is perfect.",
  },
  {
    name: "Pallav Sameer",
    rating: 5,
    content:
      "One of the best way of teaching that I have ever seen. Teachers are as friendly as you think. Easy to get coding beginners to high standards Good environment and unique teaching style Classes are well furnished. Affordable price that anyone can bear it.",
  },
];

const teachers = [
  {
    img: meetTheTeacherRamjeet,
    name: "Ramjeet Sir",
  },
  {
    img: meetTheTeacherRajesh,
    name: "Rajesh Sir",
  },
  {
    img: meetTheTeacherRuchika,
    name: "Ruchika Verma Ma'am",
  },
  {
    img: meetTheTeacherJasmeher,
    name: "Jasmeher Sir",
  },
  {
    img: meetTheTeacherSwati,
    name: "Swati Ma'am",
  },
  {
    img: meetTheTeacherShashank,
    name: "Shashank Sir",
  },

  {
    img: meetTheTeacherAkaash,
    name: "Aakash Sir",
  },
  {
    img: meetTheTeacherChetna,
    name: "Chetna Ma'am",
  },
  {
    img: placeholder,
    name: "Vinod Sir",
  },
];

const Home = () => {
  const theme = useTheme();
  const isMediumScreens = useMediaQuery("(min-width: 990px)");
  const word = "UNLOCKING POTENTIAL, BUILDING BRIGHT FUTURES";
  const letters = word.split(" ");

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.title = "BIIT Classes | Home";
    const video = document.getElementById("whyUsVideo").autoplay;
  }, []); //eslint-disable-line

  return (
    <>
      {/* HERO SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "90vh",
          pt: "80px",
          px: isMediumScreens ? "40px" : "20px",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
          cursor: "default",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          // pt="60px"
          gap="1rem"
          sx={{ textAlign: isMediumScreens ? "left" : "center" }}
        >
          <Box>
            <Typography
              variant="display"
              fontSize={"80px"}
              lineHeight={1}
              display={isMediumScreens ? "none" : "block"}
              component="h1"
            >
              {letters.map((letter, index) => {
                if (index === 0 || index === 1) {
                  return (
                    <>
                      <span key={index} className="letter">
                        {" "}
                        {letter}{" "}
                      </span>
                      <Box
                        display={isMediumScreens ? "inline-block" : "none"}
                        width="175px"
                        height="75px"
                        borderRadius="22px"
                        sx={{
                          background: `url(${index === 0 ? hero : hero2})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        className="letter"
                      />
                    </>
                  );
                } else {
                  return (
                    <span key={index} className="letter">
                      {" "}
                      {letter}{" "}
                    </span>
                  );
                }
              })}
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontSize: "17rem",
                textAlign: "center",
                display: isMediumScreens ? "block" : "none",
              }}
            >
              BIIT CLASSES
            </Typography>
          </Box>
          <FlexBetween
            gap="2.5rem"
            flexDirection={isMediumScreens ? "row" : "column"}
          >
            <Box flex={1} minHeight="40vh" width="100%">
              <Box
                width="100%"
                minHeight="40vh"
                sx={{
                  background: `url(${hero3})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "52px",
                }}
              />
            </Box>
            <Box
              flex={1}
              minHeight="40vh"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              gap="40px"
            >
              <Typography
                variant="h3"
                sx={{ display: isMediumScreens ? "block" : "none" }}
              >
                UNLOCKING POTENTIAL, BUILDING BRIGHT FUTURES
              </Typography>
              <Typography sx={{ width: isMediumScreens ? "80%" : "100%" }}>
                Elevate Your IT Journey with BIIT Classes. We offer quality
                education and diverse courses, igniting your career prospects in
                the dynamic world of information technology. Unlock your
                potential with us today.
              </Typography>
            </Box>
          </FlexBetween>
        </Box>
      </Box>

      {/* OUR COURSES SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          minHeight="70vh"
          gridTemplateColumns="1fr 2fr"
          gap="2rem"
          px={isMediumScreens ? "40px" : "20px"}
          pt="80px"
          pb="20px"
          sx={{
            "& > div": {
              gridColumn: isMediumScreens ? undefined : "span 12",
              alignItems: isMediumScreens ? undefined : "center",
            },
          }}
        >
          <FlexBetween
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.25rem",
            }}
          >
            <Typography
              variant="display"
              component="h2"
              sx={{
                textAlign: isMediumScreens ? "left" : "justify",
                width: "100%",
              }}
            >
              Our Courses
            </Typography>
            <Typography
              sx={{
                mb: "3rem",
                textAlign: isMediumScreens ? "left" : "justify",
              }}
            >
              Explore our diverse courses designed for all levels. Whether
              you're new to programming or a seasoned pro, we've got you
              covered. Join us and unlock your potential with BIIT Classes!
              Browse now for a transformative learning experience!
            </Typography>
            <Button variant="contained" sx={{ mb: "2rem" }}>
              <Link to="/courses">Browse All Courses!</Link>
            </Button>
          </FlexBetween>
          <FlexBetween
            sx={{
              gap: "1.5rem",
              justifyContent: "flex-start",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.background.main,
              },
            }}
          >
            <CourseCard
              title="MERN Stack Web Development"
              description="Master full stack web development technologies like MongoDB, Express.JS, React JS, and Node.JS"
              buttonText="Explore Course"
              imageSrc={mernStack}
            />
            <CourseCard
              title="Wordpress"
              description="Explore Wordpress and its features"
              buttonText="Explore Course"
              imageSrc={wordpress}
            />
            <CourseCard
              title="Python for Data Science"
              description="Learn data analysis and machine learning using Python."
              buttonText="Explore Course"
              imageSrc={dataScience}
            />
          </FlexBetween>
        </Box>
      </Box>
      {/* ABOUT US SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "90vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          minHeight="90vh"
          gridTemplateColumns="1fr 1fr "
          px={isMediumScreens ? "40px" : "20px"}
          pt="80px"
          pb="20px"
          gap="1rem"
          sx={{
            "& > div": {
              gridColumn: isMediumScreens ? undefined : "span 12",
              alignItems: isMediumScreens ? undefined : "center",
            },
          }}
        >
          <FlexBetween
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.5rem",
            }}
          >
            <Typography variant="display" component="h2">
              Who We Are
            </Typography>
            <Typography
              sx={{
                width: isMediumScreens ? "90%" : "100%",
                textAlign: isMediumScreens ? "left" : "justify",
              }}
            >
              BIIT Classes is a leading Delhi institute offering top-tier
              coaching for BCA and MCA IGNOU students. Our innovative
              methodology imparts smart knowledge and examination success. Since
              2016, we've provided professional training in Java, Front End Web
              Development, Full Stack Web Development MERN STACK, programming
              languages, networking, and software development. Led by seasoned
              professionals, our educators and trainers bring domain expertise
              to every class. Our wide range of courses accommodates diverse
              preferences, catering to students, working professionals, and IT
              experts seeking knowledge enhancement and career growth. Our
              unwavering commitment to quality training has propelled us as a
              prominent training & development hub. Join us to elevate your
              skills and stand out in the industry.
            </Typography>
            <Button variant="contained" sx={{ mb: "1rem" }}>
              <Link to="/about-us">Know More</Link>
            </Button>
          </FlexBetween>
          <Box
            sx={{
              backgroundImage: `url(${about})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "5rem",
              width: "100%",
              transition: "500ms ease",
              minHeight: "22rem",
            }}
          ></Box>
        </Box>
      </Box>
      {/* WHY US SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "90vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          minHeight="90vh"
          gridTemplateColumns="1fr 1fr "
          px={isMediumScreens ? "40px" : "20px"}
          pt="80px"
          pb="20px"
          gap="1.5rem"
          sx={{
            "& > div": {
              gridColumn: isMediumScreens ? undefined : "span 12",
              alignItems: isMediumScreens ? undefined : "center",
            },
          }}
        >
          <Box
            sx={{
              // backgroundImage: `url(${whyUs})`,
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              borderRadius: "5rem",
              width: "100%",
              transition: "500ms ease",
              minHeight: "22rem",
              display: isMediumScreens ? "block" : "none",
              overflow: "hidden",
            }}
          >
            <Box
              component="video"
              src={whyUsVideo}
              autoPlay="true"
              loop
              muted
              id="whyUsVideo"
              sx={{
                width: "100%",
                height: "800px",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
          <FlexBetween
            sx={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1.5rem",
            }}
          >
            <Typography variant="display" component="h2">
              Why Choose Our Courses
            </Typography>
            <Box
              sx={{
                // backgroundImage: `url(${whyUs})`,
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                borderRadius: "5rem",
                width: "100%",
                transition: "500ms ease",
                minHeight: "22rem",
                display: isMediumScreens ? "none" : "block",
                overflow: "hidden",
              }}
            >
              <Box
                component="video"
                src={whyUsVideo}
                autoPlay="true"
                loop
                muted
                id="whyUsVideo"
                sx={{
                  width: "100%",
                  height: "800px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <Box>
              <List>
                <ListItem sx={{ pl: 0 }}>
                  <ListItemText
                    primary="Proven Success"
                    secondary="BIIT Classes is renowned for delivering exceptional coaching, ensuring BCA and MCA IGNOU students achieve academic success."
                  />
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                  <ListItemText
                    primary="Unique Methodology"
                    secondary="Our specialized teaching approach imparts both smart knowledge and examination triumph, setting you up for achievement."
                  />
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                  <ListItemText
                    primary="Comprehensive Training"
                    secondary="Since 2016, we've offered professional training in Java, web design, programming, networking, and software development, enabling holistic skill growth."
                  />
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                  <ListItemText
                    primary="Expert-Led Learning"
                    secondary="Seasoned professionals lead our institute, guiding a team of educators and trainers with extensive domain expertise."
                  />
                </ListItem>
              </List>
            </Box>
          </FlexBetween>
        </Box>
      </Box>
      {/* MEET THE TEACHERS SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "90vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 2fr"
          px={isMediumScreens ? "40px" : "20px"}
          pt="80px"
          pb="20px"
          gap="1rem"
          sx={{
            "& > div": {
              gridColumn: isMediumScreens ? undefined : "span 12",
              alignItems: isMediumScreens ? undefined : "center",
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="1.5rem"
          >
            <Typography
              variant="display"
              component="h2"
              sx={{ textAlign: isMediumScreens ? "left" : "center" }}
            >
              Meet The Teachers
            </Typography>
            <Typography sx={{ textAlign: isMediumScreens ? "left" : "center" }}>
              Meet the Brilliant Minds Behind Your Success! Our team of
              experienced educators is dedicated to guiding and inspiring you.
              With extensive subject knowledge and a passion for teaching, our
              teachers create an engaging learning environment. They blend
              expertise with empathy, nurturing your growth and ensuring every
              lesson is a step closer to your aspirations. Get ready to learn
              from the best and embark on an educational journey that's truly
              transformative.
            </Typography>
            {/* <Button variant="contained" size="large">
              Meet The Teachers
            </Button> */}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="1rem"
            sx={{
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.background.main,
              },
            }}
          >
            {teachers.map((teacher, index) => (
              <TeacherProfileCard
                name={teacher.name}
                title=""
                bio=""
                avatarSrc={teacher.img}
                key={index}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {/* REVIEWS SECTION */}
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          px={isMediumScreens ? "40px" : "20px"}
          pt="80px"
          pb="20px"
          gap="3rem"
          sx={{ textAlign: isMediumScreens ? "left" : "center" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="display" component="h2">
              Student Voices: Our Institution's Impact
            </Typography>
            <Typography>
              Listen to the Experiences of Our Students as They Share How Our
              Institution Has Transformed Their Learning Journey. Read Their
              Testimonials and Feel Their Journey.
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="1.5rem"
            pb="30px"
            sx={{
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.background.main,
              },
            }}
          >
            {testimonials.map((testimonial) => (
              <TestimonialCard
                name={testimonial.name}
                rating={testimonial.rating}
                content={testimonial.content}
                wordLimit={350}
                key={testimonial.name}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {/* CTA SECTION */}
      <Box
        sx={{
          width: "100%",
          minHright: "70vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="2rem"
          px={isMediumScreens ? "40px" : "20px"}
          py="80px"
          sx={{ textAlign: isMediumScreens ? "left" : "center" }}
        >
          <Typography variant="display" component="h2">
            What Are You Waiting For?
          </Typography>
          <Typography
            sx={{
              width: isMediumScreens ? "70%" : "100%",
              textAlign: "center",
            }}
          >
            Don't let opportunities slip away! Embrace the chance to transform
            your skills, broaden your knowledge, and embark on a journey of
            growth. Join BIIT Classes today and empower yourself with the
            education that paves the way for success. Whether you're a student
            seeking excellence or a professional aiming for advancement, our
            expert-led courses are tailored to elevate your potential. Take the
            leap and shape your future now!
          </Typography>
          <Link to="/contact-us">
            <Button variant="contained">
              Enroll Now and Shape Your Future
            </Button>
          </Link>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Home;
