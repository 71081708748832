import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Footer from "../Footer/Footer";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import gallery01 from "../../static/gallery01.jpg";
import gallery02 from "../../static/gallery02.jpg";
import gallery03 from "../../static/gallery03.jpg";
import gallery04 from "../../static/gallery04.jpg";
import gallery05 from "../../static/gallery05.jpg";
import gallery06 from "../../static/gallery06.jpg";
import gallery07 from "../../static/gallery07.jpg";
import gallery08 from "../../static/gallery08.jpg";
import gallery09 from "../../static/gallery09.jpg";
import gallery10 from "../../static/gallery10.jpg";

const images = [
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
];

const Gallery = () => {
  const isNonMediumScreens = useMediaQuery("(min-width: 990px)");
  useEffect(() => {
    document.title = "BIIT Classes | Gallery";
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          py: "80px",
          px: isNonMediumScreens ? "40px" : "20px",
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt="40px"
          mb="30px"
        >
          <Typography variant="display" textAlign="center" component="h1">
            Gallery
          </Typography>
        </Box>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 1000: 3 }}
        >
          <Masonry>
            {images.map((image, i) => (
              <img
                key={i}
                src={image}
                style={{ width: "100%", display: "block" }}
                alt=""
                loading="lazy"
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Box>
      <Footer />
    </>
  );
};

export default Gallery;
