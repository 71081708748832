import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FlexBetween from "../../components/FlexBetween";
import hero2 from "../../static/hero_2.jpg";
import ourMission from "../../static/our-mission.jpg";
import about02 from "../../static/about-02.jpg";
import about03 from "../../static/about-03.jpg";
import { Expo, gsap } from "gsap";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const AboutUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const theme = useTheme();
  const minSwipeDistance = 50;
  const carousel = [
    {
      img: hero2,
      desc: " At BIIT Classes, we believe in empowering your learning journey by offering courses that adapt to your pace and comfort. Whether you're a working professional, a dedicated student, or someone seeking personal growth, our tailored approach ensures that education enhances your life without disrupting it. Embrace the freedom to learn on your terms while receiving comprehensive support and guidance at every step. Elevate your skills, elevate your comfort, and achieve your goals with BIIT Classes.",
      title: "Empower Learning Your Way: Tailored Classes for Your Lifestyle",
      cta: "Start Learning on Your Terms Today!",
      link: "/",
    },
  ];
  const isNonMediumScreens = useMediaQuery("(min-width: 990px)");

  const slideFadeOut = () => {
    gsap.to(".hero-anim", {
      duration: 1,
      opacity: 0,
      y: -20,
      ease: Expo.easeInOut,
      stagger: 0.1,
    });
  };

  const slideFadeIn = () => {
    gsap.to(".hero-anim", {
      duration: 1,
      opacity: 1,
      y: 0,
      ease: Expo.easeInOut,
      stagger: 0.1,
    });
  };

  const prevSlide = () => {
    slideFadeOut();
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? carousel.length - 1 : currentIndex - 1;
    setTimeout(() => {
      setCurrentIndex(newIndex);
      slideFadeIn();
    }, 1000);
  };
  const NextSlide = () => {
    slideFadeOut();
    const isLastSlide = currentIndex === carousel.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setTimeout(() => {
      setCurrentIndex(newIndex);
      slideFadeIn();
    }, 1000);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) isLeftSwipe ? NextSlide() : prevSlide();
  };

  useEffect(() => {
    document.title = "BIIT Classes | About Us";
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "90vh",
          position: "relative",
          zIndex: 10,
          color: theme.palette.text.main,
        }}
      >
        <Box
          display="grid"
          minHeight="92vh"
          gridTemplateColumns="2fr 1fr"
          gridTemplateRows={isNonMediumScreens ? "1fr 2fr" : "1fr 1fr"}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          px="40px"
          pt="80px"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
            },
          }}
        >
          {/* Title Section */}
          <FlexBetween
            sx={{
              justifyContent: "flex-start",
              textAlign: isNonMediumScreens ? "left" : "center",
              width: isNonMediumScreens ? "90%" : "100%",
              // borderTop: `1px solid ${theme.palette.text.main}`,
            }}
          >
            <Typography variant="display" className="hero-anim" component="h1">
              {carousel[currentIndex].title}
            </Typography>
          </FlexBetween>
          <Box sx={{ display: isNonMediumScreens ? "block" : "none" }}>
            {/* <Button
              sx={{
                background: "none",
                width: "50%",
                height: "100%",
                borderRadius: "0",
                maxHeight: isNonMediumScreens ? undefined : "10rem",
              }}
              onClick={prevSlide}
              className="text-reset"
            >
              <ArrowBackOutlined sx={{ fontSize: "70px" }} />
            </Button>
            <Button
              sx={{
                background: "none",
                width: "50%",
                height: "100%",
                borderRadius: "0",
                maxHeight: isNonMediumScreens ? undefined : "10rem",
                // borderLeft: `1px solid ${theme.palette.text.main}`,
              }}
              onClick={NextSlide}
              className="text-reset"
            >
              <ArrowForwardOutlined sx={{ fontSize: "70px" }} />
            </Button> */}
          </Box>

          {/* Image Section */}
          <Box
            sx={{
              backgroundImage: `url(${carousel[currentIndex].img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "5rem",
              width: isNonMediumScreens ? "90%" : "100%",
              transition: "500ms ease",
              minHeight: "22rem",
              mb: isNonMediumScreens ? undefined : "1.5rem",
            }}
          ></Box>

          {/* Description Section */}
          <Box
            sx={{
              // borderLeft: `1px solid ${theme.palette.text.main}`,
              pr: isNonMediumScreens ? "40px" : "0",
              display: "flex",
              justifyContent: "center",
              alignItems: isNonMediumScreens ? "flex-start" : "center",
              position: "relative",
              flexDirection: "column",
              gap: "1.25rem",
            }}
          >
            <Typography
              sx={{
                opacity: "0.75",
                textAlign: isNonMediumScreens ? "left" : "center",
              }}
              className="hero-anim"
            >
              {carousel[currentIndex].desc}
            </Typography>
            <Button variant="contained" className="hero-anim">
              <Link to={carousel[currentIndex].link}>
                {carousel[currentIndex].cta}
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "80vh",
          pt: "80px",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          px={isNonMediumScreens ? "40px" : "20px"}
          gap="2rem"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
              alignItems: isNonMediumScreens ? undefined : "center",
              textAlign: isNonMediumScreens ? "left" : "center",
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap="1.5rem">
            <Typography variant="display" component="h2">
              Our Mission
            </Typography>
            <Typography>
              At BIIT Classes, our mission transcends the ordinary. We are
              guided by a singular purpose - to empower learners with the
              knowledge, skills, and confidence they need to excel in the world
              of information technology. Our journey as an educational
              institution is deeply rooted in a commitment to your success.
            </Typography>
            <Typography>
              We understand that every student is unique, with distinct talents
              and aspirations. Our mission is to harness that uniqueness and
              channel it towards a path of technical excellence. We're not just
              here to meet your educational expectations; we're here to exceed
              them.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${ourMission})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "5rem",
              width: "100%",
              transition: "500ms ease",
              minHeight: "60vh",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "80vh",
          pt: "80px",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          px={isNonMediumScreens ? "40px" : "20px"}
          gap="2rem"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
              alignItems: isNonMediumScreens ? undefined : "center",
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${about02})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "5rem",
              width: "100%",
              transition: "500ms ease",
              minHeight: "60vh",
              display: isNonMediumScreens ? "block" : "none",
            }}
          ></Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="1.5rem"
            sx={{ textAlign: isNonMediumScreens ? "left" : "center" }}
          >
            <Typography variant="display" component="h2">
              Customized Learning Paths
            </Typography>
            <Box
              sx={{
                backgroundImage: `url(${about02})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "5rem",
                width: "100%",
                transition: "500ms ease",
                minHeight: "60vh",
                display: isNonMediumScreens ? "none" : "block",
              }}
            ></Box>
            <Typography>
              We recognize that one size does not fit all in education. That's
              why we take a personalized approach to your learning journey. Our
              customized learning paths are meticulously crafted to align with
              your individual strengths, interests, and goals.
            </Typography>
            <Typography>
              Whether you're pursuing IGNOU BCA or MCA courses or exploring the
              broader landscape of IT, our learning paths adapt to your needs.
              Our courses extend beyond the conventional, catering to working
              professionals and students alike.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "80vh",
          pt: "80px",
          color: theme.palette.text.main,
          position: "relative",
          zIndex: 10,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr"
          px={isNonMediumScreens ? "40px" : "20px"}
          gap="2rem"
          sx={{
            "& > div": {
              gridColumn: isNonMediumScreens ? undefined : "span 12",
              alignItems: isNonMediumScreens ? undefined : "center",
              textAlign: isNonMediumScreens ? "left" : "center",
            },
          }}
        >
          <Box display="flex" flexDirection="column" gap="1.5rem">
            <Typography variant="display" component="h2">
              Diverse Course Offerings
            </Typography>
            <Typography>
              For those already in the workforce or students seeking a diverse
              skill set, we provide an array of courses. Dive into the world of
              programming with our comprehensive Java course or explore the
              realm of web development with our MERN Stack Web Development
              program. If front-end or back-end development is your passion,
              we've got you covered there too.
            </Typography>
            <Typography>
              Our commitment to providing diverse courses extends to working
              professionals seeking to enhance their skill sets and students
              looking to broaden their horizons. We recognize that the IT
              industry is multifaceted, and our courses are designed to prepare
              you for success in your chosen specialization
            </Typography>
            <Typography>
              But our commitment goes beyond just theory. At BIIT Classes, we
              emphasize real-world applicability. That's why our courses include
              live project training, ensuring that you not only understand the
              concepts but also know how to apply them in practical scenarios.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${about03})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "5rem",
              width: "100%",
              transition: "500ms ease",
              minHeight: "60vh",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHright: "70vh",
          color: theme.palette.text.main,
          zIndex: 10,
          position: "relative",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="2rem"
          px={isNonMediumScreens ? "40px" : "20px"}
          py="80px"
          sx={{ textAlign: isNonMediumScreens ? "left" : "center" }}
        >
          <Typography variant="display" component="h2">
            Join Us Today
          </Typography>
          <Typography
            sx={{
              width: isNonMediumScreens ? "70%" : "100%",
              textAlign: "center",
            }}
          >
            Join us at BIIT Classes, where your educational journey is not just
            a pathway; it's a transformative odyssey destined for success.
            Together, we embark on a profound experience that shapes not only
            your skills but also your perspective, illuminating a brighter
            future in the dynamic and ever-evolving world of information
            technology.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AboutUs;
