import React from "react";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";

const TeacherProfileCard = ({ name, title, bio, avatarSrc }) => {
  return (
    <Card
      sx={{
        minWidth: 300,
        maxWidth: 300,
        m: 2,
        minHeight: "525px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        width="100%"
        height="450px"
        image={avatarSrc}
        alt={title}
      />
      <CardContent
        sx={{
          textAlign: "center",
          background: "#ffffff11",
          backdropFilter: "blur(20px)",
          boxShadow: 2,
          flex: 1,
        }}
      >
        <Typography variant="h6" component="h2">
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {bio}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TeacherProfileCard;
